

















































import {Component, Prop, Vue} from "vue-property-decorator";
import RegexUtils from "@/utils/RegexUtils";
import DocumentService from "@/services/DocumentService";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import CreateW9RequestDTO from "@/dto/archive/CreateW9RequestDTO";
import {BvEvent} from "bootstrap-vue";
import {CounterpartyType} from "@/constants/CounterpartyType";
import PortalInput from "@/components/common/PortalInput.vue";
import {InputType} from "@/components/common/InputType";
import PortalSelect from "@/components/common/PortalSelect.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import {IContractor} from "@/components/payroll/business/employments/contractors/Contractor";
import ContractorService from "@/components/payroll/business/employments/contractors/ContractorService";
import SelectOption from "@/components/common/SelectOption";
import {ifValid, processError} from "@/utils/ComponentUtils";
import {ContractorFilter} from "@/dto/payroll/Filters";
import Workspaces from "@/state/Workspaces";
import FillableDocumentDataDTO from "@/dto/archive/FillableDocumentDataDTO";
import CompanyService from "@/services/CompanyService";

const AppModule = namespace("App");

@Component({
	computed: {
		InputType() {
			return InputType
		}
	},
	components: {PortalCheckbox, PortalSelect, PortalInput}
})
export default class RequestW9Modal extends Vue {

	private successful = false;

	private message = "";

	private contractors: Array<IContractor> = [];

	@AppModule.State
	private loading!: boolean;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	private request = new CreateW9RequestDTO();

	private tabIndex = 0;

	@Prop()
	private companyId!: number;

	@Prop()
  private onSaved!: (sublimeFilling: boolean, form: FillableDocumentDataDTO) => void;

	public mounted(): void {
		this.request.employerId = this.companyId;
		this.loadAllContractors()
	}

	private buildOptions(): Array<SelectOption> {
		return this.contractors.map(contractor => SelectOption.builder()
			.title(contractor.details.name + " – " + contractor.details.email + " – " + contractor.counterpartyType)
			.value(contractor.id)
			.build())
	}

	private loadAllContractors() {
		this.stopLoading();
		const filter = new ContractorFilter();
		filter.pageSize = 100
		filter.employerId = this.$wss.getCurrent.id;
		ContractorService.getAllByFilter(filter).then(
			res => {
				this.contractors = res.data.data;
				this.stopLoading();
			}
		)
	}

	private save(): void {
		ifValid(this, () => {
			this.message = "";
			this.startLoading();
			this.request.counterpartyType = this.contractors.find(contractor => contractor.id === this.request.contractorId)!.counterpartyType
			DocumentService.registerW9(this.request).then(
				(ok) => {
					this.successful = true;
          this.onSaved(this.request.sublimeFilling!, ok.data);
					this.$modal.hideAll();
				},
				error => {
					this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
				}
			).then(() => this.stopLoading());
		})
	}

}
