import axios, {AxiosResponse} from "axios";
import {IContractor} from "@/components/payroll/business/employments/contractors/Contractor";
import {GENERAL_CONTRACTOR_SERVICE} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import RegisterContractorPayload from "@/components/payroll/business/employments/contractors/RegisterContractorPayload";
import {CounterpartyType} from "@/constants/CounterpartyType";
import QueryUtils from "@/utils/QueryUtils";
import {ContractorFilter} from "@/dto/payroll/Filters";
import Pageable from "@/dto/Pageable";

class ContractorService {

    public getAllByFilter(filter: ContractorFilter): Promise<AxiosResponse<Pageable<IContractor>>> {
        const queryString = QueryUtils.buildQueryString(
            ["name", filter.name],
            ["employerId", filter.employerId],
            ["contractorType", filter.contractorType],
            ["pageNumber", filter.pageNumber],
            ["pageSize", filter.pageSize],
            ["orderField", filter.orderField],
            ["orderDirection", filter.orderDirection]
        );
        return axios.get<Pageable<IContractor>>(`${GENERAL_CONTRACTOR_SERVICE}${queryString}`, {headers: authHeader()})
    }

    public registerContractor(payload: RegisterContractorPayload): Promise<AxiosResponse<void>> {
        return axios.post<void>(`${GENERAL_CONTRACTOR_SERVICE}`, payload, {headers: authHeader()})
    }
}

export default new ContractorService();