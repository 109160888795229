import {CounterpartyType} from "@/constants/CounterpartyType";

export default class CreateW9RequestDTO {

    contractorId: number | null = null;

    employerId: number | null = null;

    period: string | null = null;

    counterpartyType: CounterpartyType | null = null;

    sublimeFilling: boolean | null = false

    selfFilling: boolean | null = false;
}